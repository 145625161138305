import { FaReact } from "react-icons/fa";
import { SiMaterialui, SiRedux } from "react-icons/si";

const personalProjects = [
  {
    image: "./memory-game.jpg",
    link: "https://cdiaz-dev.gitlab.io/memory-game/",
    title: "Memory Game",
    type: "Game",
    technologies: [
      { title: "React", icon: FaReact },
      { title: "Material UI", icon: SiMaterialui },
    ],
  },
  {
    image: "./todo-list.png",
    link: "https://cdiaz-dev.gitlab.io/todo-list/",
    title: "Todo List",
    type: "Utility",
    technologies: [
      { title: "React", icon: FaReact },
      { title: "Material UI", icon: SiMaterialui },
      { title: "Redux", icon: SiRedux },
    ],
  },
  {
    image: "./book-search.jpg",
    link: "https://cdiaz-dev.gitlab.io/book-search/",
    title: "Book Search",
    type: "Learning",
    technologies: [
      { title: "React", icon: FaReact },
      { title: "Material UI", icon: SiMaterialui },
    ],
  },
  {
    image: "./password-generator.jpg",
    link: "https://cdiaz-dev.gitlab.io/password-generator/",
    title: "Password Generator",
    type: "Utility",
    technologies: [
      { title: "React", icon: FaReact },
      { title: "Material UI", icon: SiMaterialui },
    ],
  },
  {
    image: "./country-search.png",
    link: "https://cdiaz-dev.gitlab.io/country-search/",
    title: "Country Search",
    type: "Utility",
    technologies: [{ title: "React", icon: FaReact }],
  },
  {
    image: "./contacts.jpg",
    link: "https://cdiaz-dev.gitlab.io/contacts-management/",
    title: "Contacts Management",
    type: "Utility",
    technologies: [{ title: "React", icon: FaReact },{ title: "Redux", icon: SiRedux },],
  },
  {
    image: "./qr-code.svg",
    link: "https://cdiaz-dev.gitlab.io/qr-generator/",
    title: "QR Code Generator",
    type: "Utility",
    technologies: [{ title: "React", icon: FaReact }],
  },
];
export default personalProjects;
