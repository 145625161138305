//@libs
import { useRoutes } from "react-router-dom";
//@components
import Layout from "layout";
//@pages
import Home from "pages/home";
import Contact from "pages/contact";
import Projects from "pages/projects";
import Skills from "pages/skills";
import Certifications from "pages/certifications";

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/contact", element: <Contact /> },
      { path: "/certifications", element: <Certifications /> },
      { path: "/projects", element: <Projects /> },
      { path: "/skills", element: <Skills /> }
    ],
  },
];

const Router = () => {
  return useRoutes(routes);
};
export default Router;
