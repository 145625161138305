import {
  Group,
  ActionIcon,
  Tooltip,
  useMantineColorScheme,
} from "@mantine/core";
import { FaSun, FaMoon } from "react-icons/fa";

const OptionsIcons = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  return (
    <Group spacing={2} position="right" noWrap>
      <Tooltip label="Mode" withArrow>
        <ActionIcon size="lg" onClick={toggleColorScheme}>
          {colorScheme === "dark" ? (
            <FaMoon size={18} stroke={1.5} />
          ) : (
            <FaSun size={18} stroke={1.5} />
          )}
        </ActionIcon>
      </Tooltip>
      {/* <Tooltip label="Language" withArrow>
        <ActionIcon size="lg">
          <FaLanguage size={18} stroke={1.5} />
        </ActionIcon>
      </Tooltip> */}
    </Group>
  );
};

export default OptionsIcons;
