//@libs
import { Container, createStyles, Box } from "@mantine/core";
import { Outlet } from "react-router-dom";
//@components
import Topbar from "./Topbar";
import Footer from "./Footer";
import Page from "components/Page";

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: 72,
    width: "100%",
  },
}));

const Layout = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.root}>
      <Topbar />
      <Container className={classes.main} size="lg">
        <Page>
          <Outlet />
        </Page>
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
