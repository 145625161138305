//@libs
import { useState } from "react";
//@mantine-ui
import {
  Card,
  Image,
  Text,
  Avatar,
  createStyles,
  Modal,
  Button,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "inherit",
  },
  action: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },
  footer: {
    marginTop: theme.spacing.md,
  },
}));

const CertificationCard = ({
  className,
  image,
  link,
  title,
  description,
  year,
  rating,
  resource,
  validity,
  ...others
}) => {
  const { classes, cx } = useStyles();
  const [showDetails, setShowDetails] = useState(false);

  const handleDetails = () => setShowDetails((prev) => !prev);
  return (
    <>
      <Card
        withBorder
        radius="md"
        className={cx(classes.card, className)}
        {...others}
      >
        <Card.Section
          style={{ display: "flex", justifyContent: "center", padding: 16 }}
        >
          <Avatar
            src={image}
            alt="it's me"
            style={{ height: 200, width: "auto" }}
          />
        </Card.Section>
        <Text size="md" weight={600}>
          {title}
        </Text>
        <Text size="sm" color="dimmed">
          {validity}
        </Text>

        <Button
          variant="default"
          mt={24}
          mb={8}
          onClick={handleDetails}
          fullWidth
        >
          Ver certificación
        </Button>
      </Card>
      <Modal
        opened={showDetails}
        onClose={handleDetails}
        title={title}
        radius="md"
        size="xl"
      >
        <Image radius="md" src={resource} alt="Random unsplash image" />
      </Modal>
    </>
  );
};
export default CertificationCard;

