import { FaGitlab, FaLinkedin } from "react-icons/fa";

const contactOptions = [
  { title: "Gitlab", href: "https://gitlab.com/cdiaz-dev", icon: FaGitlab },
  {
    title: "Linkedin",
    href: "https://www.linkedin.com/in/carlos-d%C3%ADaz-palacios-0a777111a/",
    icon: FaLinkedin,
  },
];
export default contactOptions;
