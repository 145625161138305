//@mantine-ui
import { Text, Grid } from "@mantine/core";
//@components
import PersonalProjects from "components/Projects/PersonalProjects";
import ProfessionalProjects from "components/Projects/ProfessionalProjects";

const Projects = () => {
  return (
      <Grid>
        <Grid.Col xs={12}>
          <Text
            sx={(theme) => ({
              fontWeight: 600,
              marginBottom: theme.spacing.md,
              fontSize: theme.fontSizes.xl,
            })}
            color="dimmed"
          >
            Professional Projects
          </Text>
        </Grid.Col>
        <Grid.Col xs={12}>
          <ProfessionalProjects />
        </Grid.Col>
        <Grid.Col xs={12}>
          <Text
            sx={(theme) => ({
              fontWeight: 600,
              marginTop: theme.spacing.md,
              marginBottom: theme.spacing.md,
              fontSize: theme.fontSizes.xl,
            })}
            color="dimmed"
          >
            Personal Projects
          </Text>
        </Grid.Col>
        <Grid.Col xs={12}>
          <PersonalProjects />
        </Grid.Col>
      </Grid>
  );
};
export default Projects;
