//@mantine-ui
import { createStyles, Text, Grid, Image } from "@mantine/core";
//@components
import ContactIconsList from "components/ContactIconList";
import MainTitle from "components/MainTitle";

const useStyles = createStyles((theme) => ({
  root: {
    marginTop: theme.spacing.sm,
  },
  description: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][0]
        : theme.colors.gray[7],
    maxWidth: 300,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },
  image: {
    flex: 1,
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
}));

const ContactUs = () => {
  const { classes } = useStyles();
  return (
    <Grid columns={12} className={classes.root}>
      <Grid.Col xs={12} sm={6}>
        <MainTitle title="Contact 📬" />
        <Text className={classes.description} mt="sm" mb={30}>
          Leave your email and I'll get back to you within 24 hours.
        </Text>
        <ContactIconsList variant="white" />
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Image src="./contact.svg" className={classes.image} />
      </Grid.Col>
    </Grid>
  );
};
export default ContactUs;
