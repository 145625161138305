import { Carousel } from "@mantine/carousel";
import projectsData from "data/professional-projects";
//@components
import ProjectCard from "./Card";

const SLIDES_LENGTH = 3;

const Projects = () => {
  return (
    <Carousel
      dragFree
      slideSize={`${100 / SLIDES_LENGTH}%`}
      withControls={projectsData.length > SLIDES_LENGTH}
      slideGap="md"
      align="start"
      slidesToScroll={1}
      loop
      breakpoints={[
        { maxWidth: "xs", slideSize: "100%" },
        { maxWidth: "md", slideSize: "50%" },
        { minWidth: "lg", slideSize: `${100 / SLIDES_LENGTH}%` },
      ]}
    >
      {projectsData.map((item) => (
        <Carousel.Slide key={item.title}>
          <ProjectCard {...item} />
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};
export default Projects;
