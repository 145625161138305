import { useState } from "react";

const useMode = () => {
  const [colorScheme, setColorScheme] = useState("dark");
  const toggleColorScheme = () => {
    setColorScheme(colorScheme === "dark" ? "light" : "dark");
  };
  return { colorScheme, toggleColorScheme };
};
export default useMode;
