import {
  SiMaterialui,
  SiJavascript,
  SiMongodb,
  SiMysql,
  SiRedux,
  SiPostman,
  SiPostgresql,
  SiSequelize,
  SiReacttable,
  SiPrisma
} from "react-icons/si";
import {
  FaReact,
  FaHtml5,
  FaCss3Alt,
  FaNode,
  FaAws,
  FaBitbucket,
  FaGitAlt,
  FaJira,
  FaSlack,
} from "react-icons/fa";

const skills = [
  {
    title: "Frontend",
    items: [
      { title: "Html", icon: FaHtml5 },
      { title: "Css", icon: FaCss3Alt },
      { title: "React", icon: FaReact },
      { title: "Material UI", icon: SiMaterialui },
      { title: "JavaScript", icon: SiJavascript },
      { title: "Redux", icon: SiRedux },
      { title: "React Query", icon: SiReacttable },

    ],
  },
  {
    title: "Backend",
    items: [
      { title: "NodeJS", icon: FaNode },
      { title: "MySql", icon: SiMysql },
      { title: "MongoDB", icon: SiMongodb },
      { title: "PostgreSql", icon: SiPostgresql },
      { title: "Sequelize ORM", icon: SiSequelize },
      { title: "Prisma ORM", icon: SiPrisma },

    ],
  },
  {
    title: "Cloud",
    items: [
      { title: "Aws", icon: FaAws },
      { title: "Mongo Atlas", icon: SiMongodb },
      { title: "BitBucket", icon: FaBitbucket },
    ],
  },
  {
    title: "Tools/Productivity",
    items: [
      { title: "Git", icon: FaGitAlt },
      { title: "Jira", icon: FaJira },
      { title: "Slack", icon: FaSlack },
      { title: "Postman", icon: SiPostman },
    ],
  },
];
export default skills;
