import { createStyles, Container, Text } from "@mantine/core";
import SocialIcons from "components/SocialIcons";

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: "auto",
  },
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },
  links: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export default function FooterSocial() {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Container className={classes.inner} size="lg">
        <Text color="dimmed">Copyright © Carlos Díaz Palacios {new Date().getFullYear()}</Text>
        <SocialIcons />
      </Container>
    </div>
  );
}
