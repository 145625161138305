import Router from "./routes";
import { BrowserRouter } from "react-router-dom";
import { ColorSchemeProvider, MantineProvider } from "@mantine/core";
import useMode from "./hooks/useMode";
import { NotificationsProvider } from "@mantine/notifications";

const App = () => {
  const { colorScheme, toggleColorScheme } = useMode();
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ colorScheme }}
      >
        <NotificationsProvider position="top-right">
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;
