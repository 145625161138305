//@mantine-ui
import { createStyles, Text, Stack, ThemeIcon, Box } from "@mantine/core";
//@libs
import { HiOutlineClock, HiOutlineMail } from "react-icons/hi";

const useStyles = createStyles((theme) => ({
  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.colorScheme === "dark" ? theme.white : theme.colors.gray[7],
    lineHeight: 1,
  },
  description: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors[theme.primaryColor][0]
        : theme.colors.gray[7],
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },
  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },
  social: {
    color: theme.white,
    "&:hover": {
      color: theme.colors[theme.primaryColor][1],
    },
  },
  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,
    "&::placeholder": {
      color: theme.colors.gray[5],
    },
  },
  inputLabel: {
    color: theme.black,
  },
  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));
function ContactIcon({
  icon: Icon,
  title,
  description,
  variant = "gradient",
  className,
  ...others
}) {
  const { classes, cx } = useStyles({ variant });
  return (
    <div className={cx(classes.wrapper, className)} {...others}>
      {variant === "gradient" ? (
        <ThemeIcon size={40} radius="md" className={classes.icon}>
          <Icon size={24} />
        </ThemeIcon>
      ) : (
        <Box mr="md">
          <Icon size={24} />
        </Box>
      )}

      <div>
        <Text size="xs" className={classes.title}>
          {title}
        </Text>
        <Text className={classes.description}>{description}</Text>
      </div>
    </div>
  );
}
const data = [
  {
    title: "Email",
    description: "contacto@carlosdiazpalacios.cl",
    icon: HiOutlineMail,
  },
  {
    title: "Working hours",
    description: "9 a.m. – 18 p.m.",
    icon: HiOutlineClock,
  },
];
const ContactIconsList = ({ variant }) => {
  const items = data.map((item, index) => (
    <ContactIcon key={index} variant={variant} {...item} />
  ));
  return <Stack>{items}</Stack>;
};
export default ContactIconsList;
