import { forwardRef } from "react";
//material-ui components
import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xs
  },
}));

const Page = forwardRef(
  ({ children, title = "Title", subtitle = "Subtitle", ...rest }, ref) => {
    const { classes } = useStyles();
    return (
      <div ref={ref} className={classes.root}>
        {children}
      </div>
    );
  }
);

export default Page;
