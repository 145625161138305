//@mantine-ui
import { createStyles, Title } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.colorScheme === "dark" ? theme.white : theme.colors.gray[7],
    lineHeight: 1,
  },
}));

const MainTitle = ({ title }) => {
  const { classes } = useStyles();
  return <Title className={classes.root}>{title}</Title>;
};
export default MainTitle;
