import { SiMongodb, SiMysql, SiPostgresql } from "react-icons/si";
import { FaReact, FaAws } from "react-icons/fa";

const professionalProjects = [
  {
    image: "./us-logo.svg",
    bgColor: "#FFF",
    title: "Unidad Seguros",
    year: "2023",
    description:
      "Aplicación web para la cotización y contratación de seguros de vida, auto, entre otros. Administración interna de la plataforma",
    author: {
      role: "FullStack Jr",
      name: "forEach",
      image:
        "https://foreach.cl/wp-content/uploads/2021/09/logo-foreach-png.png",
    },
    technologies: [
      { title: "React", icon: FaReact, color: "blue" },
      { title: "MySql", icon: SiMysql, color: "blue" },
      { title: "AWS", icon: FaAws, color: "gray" },
    ],
  },
  {
    image: "./frm-logo.svg",
    bgColor: "#FFF",
    title: "Fundación para la Infancia Ronald McDonald",
    year: "2022",
    description:
      "Aplicación web interna para la gestión de pacientes, familiares, servicios, visitas, generación de reportes, entre otros, en las distintas salas administradas por la Fundación para la Infancia Ronald McDonald Chile.",
    author: {
      role: "FullStack Jr",
      name: "forEach",
      image:
        "https://foreach.cl/wp-content/uploads/2021/09/logo-foreach-png.png",
    },
    technologies: [
      { title: "React", icon: FaReact, color: "blue" },
      { title: "MongoDB", icon: SiMongodb, color: "green" },
      { title: "AWS", icon: FaAws, color: "gray" },
    ],
  },
  {
    image: "./dralenco.svg",
    bgColor: "#FFF",
    title: "Dralenco",
    year: "2022",
    description:
      "Aplicación web para la gestión de evaluaciones realizadas por profesionales de la salud a estudiantes, permitiendo la medición de distintos indicadores, tales como cognitivos, sociales, etc.",
    author: {
      role: "FullStack Jr",
      name: "forEach",
      image:
        "https://foreach.cl/wp-content/uploads/2021/09/logo-foreach-png.png",
    },
    technologies: [
      { title: "React", icon: FaReact, color: "blue" },
      { title: "MongoDB", icon: SiMongodb, color: "green" },
      { title: "AWS", icon: FaAws, color: "gray" },
    ],
  },
  {
    image: "./tero.svg",
    bgColor: "#FFF",
    title: "Tero",
    year: "2022",
    description:
      "Plataforma SAAS, para la gestión de indicadores ASG, generación de reportes, entre otros, en el ámbito de la Sostenibilidad.",
    author: {
      role: "FullStack Jr",
      name: "Bill Wormeater",
      image:
        "https://images.unsplash.com/photo-1593229874334-90d965f27c42?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80",
    },
    technologies: [
      { title: "React", icon: FaReact, color: "blue" },
      { title: "AWS", icon: FaAws, color: "gray" },
      { title: "PostgreSQL", icon: SiPostgresql, color: "blue" },
    ],
  },
  {
    image: "./logo-zimexa.png",
    bgColor: "#FDAE01",
    title: "Zimexa",
    year: "2021",
    description:
      "Plataforma SAAS, para la gestión de trabajadores, certificaciones, epp, capacitaciones, entre otros, en el ámbito de Salud, Seguridad y Ambiente .",
    author: {
      role: "FullStack Jr",
      name: "Innoapsion",
      image:
        "https://images.unsplash.com/photo-1593229874334-90d965f27c42?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80",
    },
    technologies: [
      { title: "React", icon: FaReact, color: "blue" },
      { title: "AWS", icon: FaAws, color: "gray" },
      { title: "MySQL", icon: SiMysql, color: "blue" },
    ],
  },
  {
    image: "./logo-sedecc.png",
    bgColor: "#FFF",
    title: "Facturación Sedecc",
    year: "2020",
    description:
      "Aplicación web interna para la gestión de clientes, control de costos, generación de facturas (integración con terceros), entre otros.",
    author: {
      role: "Practica Profesional",
      name: "Bill Wormeater",
      image:
        "https://images.unsplash.com/photo-1593229874334-90d965f27c42?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80",
    },
    technologies: [
      { title: "React", icon: FaReact, color: "blue" },
      { title: "AWS", icon: FaAws, color: "gray" },
      { title: "MySQL", icon: SiMysql, color: "blue" },
    ],
  },
];

export default professionalProjects;
