import {
  createStyles,
  Image,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
  Grid,
} from "@mantine/core";
import { FaCheck } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: theme.spacing.xs,
  },
  content: {
    maxWidth: 480,
    marginRight: theme.spacing.xl * 3,
    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },
  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.colors.gray[7],
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 44,
    lineHeight: 1.2,
    fontWeight: 900,
    [theme.fn.smallerThan("xs")]: {
      fontSize: 28,
    },
  },
  bold: {
    color: theme.colorScheme === "dark" ? theme.white : theme.colors.gray[7],
  },
  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },
  image: {
    flex: 1,
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: "0px 6px",
  },
}));

const Home = () => {
  const { classes } = useStyles();
  const experienceYears = new Date().getFullYear() - 2020; 
  return (
    <Grid columns={12} className={classes.root}>
      <Grid.Col xs={12} md={6}>
        <Title className={classes.title}>
          👋 I'm <span className={classes.highlight}>Carlos</span>, <br />a
          Full-Stack Developer
        </Title>
        <Text color="dimmed" mt="md">
          I am passionate about technologies, seeking to improve and increase my
          knowledge, with the aim of creating efficient, scalable, intuitive and
          high-performance solutions for my clients and employers.
        </Text>
        <List
          mt={30}
          spacing="sm"
          size="sm"
          icon={
            <ThemeIcon size={20} radius="xl">
              <FaCheck size={12} stroke={1.5} />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text color="dimmed">
              <b className={classes.bold}>+{experienceYears} years of experience</b> – A couple
              of years building full stack applications.
            </Text>
          </List.Item>
          <List.Item>
            <Text color="dimmed">
              <b className={classes.bold}>Modern technologies</b> – Building
              apps with modern technologies like React, MongoDB, Serverless,
              AWS.
            </Text>
          </List.Item>
          <List.Item>
            <Text color="dimmed">
              <b className={classes.bold}>AWS certified</b> – Aws Certified
              Cloud Practitioner, valid until November 2024.
            </Text>
          </List.Item>
        </List>
        <Group mt={30}>
          <Button
            component={NavLink}
            to="/projects"
            radius="xl"
            size="md"
            className={classes.control}
            variant="gradient"
            gradient={{ from: "indigo", to: "cyan" }}
          >
            View projects
          </Button>
          <Button
            component={NavLink}
            to="/contact"
            variant="default"
            radius="xl"
            size="md"
            className={classes.control}
          >
            Contact
          </Button>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} md={6}>
        <Image src={"./main.svg"} className={classes.image} />
      </Grid.Col>
    </Grid>
  );
};
export default Home;
