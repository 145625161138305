//@mantine-ui
import { createStyles, UnstyledButton, Drawer } from "@mantine/core";
import { FaMedal, FaHome, FaTools, FaCode } from "react-icons/fa";
import { MdContacts } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  mainLink: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: theme.fontSizes.xs,
    padding: `8px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
  },
  mainLinkInner: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.fontSizes.md,
    flex: 1,
  },
  mainLinkIcon: {
    marginRight: theme.spacing.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
  },
  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    marginBottom: theme.spacing.xs,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[1],
    },
  },
  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));
const navItems = [
  { label: "Home", link: "/", icon: FaHome },
  { label: "Projects", link: "/projects", icon: FaCode },
  { label: "Skills", link: "/skills", icon: FaTools },
  { label: "Certifications", link: "/certifications", icon: FaMedal },
  { label: "Contact", link: "/contact", icon: MdContacts },
];

const DrawerMenu = ({ isOpen, onClose }) => {
  const { classes, cx } = useStyles();
  const { pathname } = useLocation();
  return (
    <Drawer opened={isOpen} onClose={onClose} padding="xl" size="md">
      {navItems.map((item) => (
        <Link
          key={item.label}
          to={item.link}
          className={cx(classes.link, {
            [classes.linkActive]: pathname === item.link,
          })}
          onClick={onClose}
        >
          <UnstyledButton className={classes.mainLink}>
            <div className={classes.mainLinkInner}>
              <item.icon
                size={24}
                className={classes.mainLinkIcon}
                stroke={1.5}
              />
              <span>{item.label}</span>
            </div>
          </UnstyledButton>
        </Link>
      ))}
    </Drawer>
  );
};

export default DrawerMenu;
