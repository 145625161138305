import { Carousel } from "@mantine/carousel";
//@components
import PersonalProjectCard from "./Card";
import personalProjectsData from "data/personal-projects.js";

const SLIDES_LENGTH = 4;

const PersonalProjects = () => {
  return (
    <Carousel
      dragFree
      slideSize={`${100 / SLIDES_LENGTH}%`}
      slideGap="md"
      withControls={personalProjectsData.length > SLIDES_LENGTH}
      align="start"
      slidesToScroll={2}
      loop
      breakpoints={[
        { maxWidth: "xs", slideSize: "50%" },
        { maxWidth: "sm", slideSize: `${100 / 3}%` },
        { minWidth: "md", slideSize: `${100 / SLIDES_LENGTH}%` },
      ]}
    >
      {personalProjectsData.map((item) => (
        <Carousel.Slide key={item.title}>
          <PersonalProjectCard {...item} />
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};
export default PersonalProjects;
