import {
  createStyles,
  Image,
  Accordion,
  Grid,
  Title,
  Avatar,
  SimpleGrid,
  Tooltip,
} from "@mantine/core";
import skills from "data/skills";

const useStyles = createStyles((theme) => ({
  title: {
    marginBottom: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },
  image: {
    flex: 1,
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
}));

const Skills = () => {
  const { classes } = useStyles();
  return (
    <Grid>
      <Grid.Col xs={6} md={6}>
        <Image src="./skills-animate.svg" className={classes.image} />
      </Grid.Col>
      <Grid.Col xs={12} md={6}>
        <Title order={2} align="left" className={classes.title}>
          Skills{" "}
          <span role="img" aria-label="on-fire">
            🔥
          </span>
        </Title>
        <Accordion
          defaultValue={["frontend", "backend", "cloud"]}
          variant="separated"
        >
          {skills.map((skill) => (
            <Accordion.Item
              className={classes.item}
              value={skill.title.toLocaleLowerCase()}
              key={skill.title}
            >
              <Accordion.Control>{skill.title}</Accordion.Control>
              <Accordion.Panel>
                <SimpleGrid cols={12} spacing="sm">
                  {skill.items.map(({ icon: Icon, title }) => (
                    <Tooltip
                      label={title}
                      position="bottom"
                      withArrow
                      key={title}
                    >
                      <Avatar size={40} radius="xl">
                        <Icon size={24} />
                      </Avatar>
                    </Tooltip>
                  ))}
                </SimpleGrid>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Grid.Col>
    </Grid>
  );
};
export default Skills;
