//@mantine-ui
import { Group, ActionIcon, Tooltip } from "@mantine/core";
//@static
import contactOptions from "data/contact-options";

const SocialIcons = () => {
  return (
    <Group spacing={2} position="right" noWrap>
      {contactOptions.map((option) => (
        <Tooltip
          key={option.title}
          label={option.title}
          withArrow
          position="top"
        >
          <a href={option.href} target="_blank" rel="noopener noreferrer">
            <ActionIcon size="lg">
              <option.icon size={18} stroke={1.5} />
            </ActionIcon>
          </a>
        </Tooltip>
      ))}
    </Group>
  );
};

export default SocialIcons;
