//@mantine-ui
import {
  Card,
  Text,
  ActionIcon,
  Badge,
  Group,
  Center,
  Avatar,
  createStyles,
  Tooltip,
} from "@mantine/core";
//@libs
import { FaCode } from "react-icons/fa";

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  title: {
    display: "block",
    marginTop: theme.spacing.lg,
    marginBottom: theme.spacing.xs / 2,
  },
  action: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    }),
  },
  rating: {
    position: "absolute",
    top: theme.spacing.xs,
    right: theme.spacing.xs + 2,
    pointerEvents: "none",
  },
  footer: {
    marginTop: theme.spacing.md,
  },
}));

const ProjectCard = ({
  className,
  image,
  bgColor,
  link,
  title,
  description,
  author,
  technologies,
  year,
  rating,
  ...others
}) => {
  const { classes, cx, theme } = useStyles();
  return (
    <Card
      withBorder
      radius="md"
      className={cx(classes.card, className)}
      {...others}
    >
      <Card.Section sx={{ backgroundColor: bgColor, padding: 24 }}>
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            height: 150,
          }}
        />
      </Card.Section>
      <Badge
        className={classes.rating}
        variant="gradient"
        gradient={{ from: "gray", to: "black" }}
      >
        {year}
      </Badge>
      <Group position="apart" className={classes.footer}>
        <Center>
          <Avatar size={32} radius="xl" mr="xs">
            <FaCode size={24} color={theme.colors.blue[5]} />
          </Avatar>
          <Text size="sm" inline color="dimmed">
            {author.role}
          </Text>
        </Center>
        <Group spacing={2} mr={0}>
          {technologies.map((technology) => (
            <Tooltip
              key={technology.title}
              label={technology.title}
              withArrow
              position="bottom"
            >
              <ActionIcon size="lg" radius="xl" className={classes.action}>
                <technology.icon
                  size={18}
                  stroke={1.5}
                  color={theme.colors[technology.color][5]}
                />
              </ActionIcon>
            </Tooltip>
          ))}
        </Group>
      </Group>
      <Text className={classes.title} weight={500}>
        {title}
      </Text>
      <Text size="sm" color="dimmed" lineClamp={4}>
        {description}
      </Text>
    </Card>
  );
};
export default ProjectCard;
