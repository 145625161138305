//@mantine-ui
import {
  Card,
  Text,
  Group,
  createStyles,
  Badge,
  Tooltip,
  ActionIcon,
} from "@mantine/core";

const useStyles = createStyles((theme, _params, getRef) => {
  const image = getRef("image");
  return {
    card: {
      position: "relative",
      height: 280,
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      [`&:hover .${image}`]: {
        transform: "scale(1.03)",
      },
    },
    image: {
      ref: image,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundSize: "cover",
      transition: "transform 500ms ease",
    },
    overlay: {
      position: "absolute",
      top: "20%",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage:
        "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .85) 90%)",
    },
    content: {
      height: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      zIndex: 1,
    },
    title: {
      color: theme.white,
      marginBottom: 5,
    },
    rating: {
      position: "absolute",
      top: theme.spacing.xs,
      right: theme.spacing.xs + 2,
      pointerEvents: "none",
    },
  };
});

const PersonalProjectCard = ({ image, title, type, link, technologies }) => {
  const { classes, theme } = useStyles();
  return (
    <Card
      p="lg"
      shadow="lg"
      className={classes.card}
      radius="md"
      component="a"
      href={link}
      target="_blank"
    >
      <div
        className={classes.image}
        style={{ backgroundImage: `url(${image})` }}
      />
      <div className={classes.overlay} />
      <div className={classes.content}>
        <div>
          <Text size="lg" className={classes.title} weight={500}>
            {title}
          </Text>
          <Badge
            className={classes.rating}
            variant="gradient"
            gradient={{ from: "yellow", to: "red" }}
          >
            {type}
          </Badge>
          <Group spacing={1}>
            {technologies.map((technology) => (
              <Tooltip
                key={technology.title}
                label={technology.title}
                withArrow
                position="bottom"
              >
                <ActionIcon size="lg" radius="xl">
                  <technology.icon
                    size={18}
                    stroke={1.5}
                    color={theme.colors.dark[2]}
                  />
                </ActionIcon>
              </Tooltip>
            ))}
          </Group>
        </div>
      </div>
    </Card>
  );
};

export default PersonalProjectCard;
