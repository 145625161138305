//@mantine-ui
import { Grid } from "@mantine/core";
//@components
import CertificationCard from "components/CertificationCard";
import MainTitle from "components/MainTitle";
//@static
import certificationsData from "data/certifications.json";

const Certifications = () => {
  return (
    <Grid columns={12}>
      <Grid.Col xs={12} sx={{marginBottom: 24}}>
        <MainTitle title="Mis certificaciones 🏅" />
      </Grid.Col>
      {certificationsData.map((certification) => (
        <Grid.Col xs={12} sm={6} md={4} key={certification.title}>
          <CertificationCard {...certification} />
        </Grid.Col>
      ))}
    </Grid>
  );
};
export default Certifications;