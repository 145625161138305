import { createStyles, Header, Group, Container, Burger, Avatar } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Link, useLocation } from "react-router-dom";
import OptionsIcons from "components/OptionsIcons";
import DrawerMenu from "./Drawer";

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[1],
  },
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 72,
    [theme.fn.smallerThan("sm")]: {
      justifyContent: "flex-start",
    },
  },
  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  burger: {
    marginRight: theme.spacing.md,
    flexGrow: 1,
    bgColor:
      theme.colorScheme === "dark"
        ? theme.colors.blue
        : theme.colors.gray[7],
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.lg,
    fontWeight: 500,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));

const navItems = [
  { label: "Inicio", link: "/" },
  { label: "Proyectos", link: "/projects" },
  { label: "Habilidades", link: "/skills" },
  { label: "Certificaciones", link: "/certifications" },
  { label: "Contacto", link: "/contact" },
];
const Topbar = () => {
  const { pathname } = useLocation();
  const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useStyles();

  const items = navItems.map((item) => (
    <Link
      key={item.label}
      to={item.link}
      className={cx(classes.link, {
        [classes.linkActive]: pathname === item.link,
      })}
    >
      {item.label}
    </Link>
  ));

  return (
    <Header height={72} fixed className={classes.header}>
      <Container className={classes.inner} size="lg">
        <Burger
          opened={opened}
          onClick={toggle}
          size="sm"
          className={classes.burger}
        />
        <Avatar radius="xl">CD</Avatar>
        <Group className={classes.links} spacing={5}>
          {items}
        </Group>
        <OptionsIcons />
      </Container>
      <DrawerMenu isOpen={opened} onClose={toggle} />
    </Header>
  );
};

export default Topbar;
